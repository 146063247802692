














































































































import { Prop, Component, Mixins } from 'vue-property-decorator'
import { Dropdowns } from '~/mixins/dropdowns'
import { BeatStore } from '~/store/beat'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { PlayerStore } from '~/store/player'
import { UserStore } from '@/store/user'
import { BeatIsFullyBoughtAndBeatIsLiked } from '@/mixins/beat-is-liked'
import { BeatAlreadyInCart } from '@/mixins/beat-already-in-cart'
import { BeatImageOrProducerLogo } from '~/mixins/beat-image-or-producer-logo'
import { AudioFileType } from '~/const/audio-file-type'
import { ParentStateType } from '~/const/parent-state-type'

@Component
export default class BeatTableItem extends Mixins(BeatIsFullyBoughtAndBeatIsLiked, Dropdowns, BeatAlreadyInCart, BeatImageOrProducerLogo) {
    @Prop({ type: Array }) beatPlaylist: IBoughtBeat[] | IBeat[]
    @Prop({ type: Number }) index: number
    @Prop({ type: Number }) fakeIndexToShow: number
    @Prop({ type: Number }) parentState: ParentStateType

    AudioFileType = AudioFileType
    ModalType = ModalType
    ParentStateType = ParentStateType

    @PlayerStore.State('isAudioPaused') isAudioPaused: boolean
    @PlayerStore.Getter('beatPlaying') beatPlaying: IBeat

    get beat(): IBeat {
        // if we are not in "My licenses" we return the simply return the obj at index === to the index passed as props
        // @ts-ignore TODO:
        if (this.parentState !== ParentStateType.Purchases) return this.beatPlaylist[this.index]

        // otherwise we remove everything but the beat obj from the array and than we return the obj at index === to the index passed as props
        // @ts-ignore TODO:
        const beatsArray = this.beatPlaylist.map((item: IBoughtBeat) => item.beat)
        return beatsArray[this.index]
    }

    get license(): ILicenseOptionOrder {
        // we remove everything but the license obj from the array and than we return the obj at index === to the index passed as props
        if (this.parentState === ParentStateType.Purchases) {
            // @ts-ignore TODO:
            const licensesArray = this.beatPlaylist.map((item: IBoughtBeat) => item.license)
            return licensesArray[this.index]
        }
        return null
    }

    get audioClasses() {
        return {
            playing: !this.isAudioPaused && this.beat === this.beatPlaying,
            paused: this.isAudioPaused && this.beat === this.beatPlaying
        }
    }

    @BeatStore.Action('demoDownloadBeat') demoDownloadBeat: (beat: IBeat) => void
    @BeatStore.Action('openPdfContact') openPdfContact: ({ beatId }: { beatId: number }) => void
    @BeatStore.Action('downloadFileType') downloadFileType: ({ beatId, fileType }: { beatId: IBeat['id']; fileType: AudioFileType }) => void
    @UserStore.Action('toggleLike') toggleLike: () => void
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (IModalParams: IModalParams) => void

    openShareBeatModal() {
        this.SET_ACTIVE_MODAL({ modal: ModalType.Share, payload: this.beat })
        this.closeMenuDropdown()
    }

    togglePlayStatus() {
        const dispatchParams = { beats: this.beatPlaylist, index: this.index, route: this.$route.fullPath }
        // if we are in "My Licenses" we need to remove the license obj and id number from the beatPlaylist array
        // @ts-ignore TODO:
        if (this.parentState === ParentStateType.Purchases) dispatchParams.beats = this.beatPlaylist.map((item: IBoughtBeat) => item.beat)
        // then we call the togglePlayStatus passing the right params
        this.$store.dispatch('player/togglePlayStatus', dispatchParams)
    }
}
