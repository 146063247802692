









import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import BeatTable from '@/components/BeatTable.vue'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import HeaderSection from '@/components/HeaderSection.vue'
import { UserStore } from '@/store/user'
import { ModalStore } from '~/store/modal'
import { AuthStore } from '~/store/auth'
import { ParentStateType } from '~/const/parent-state-type'

@Component({ components: { BeatTable, HeaderSection, VLoadSpinner } })
export default class PurchasesPage extends Vue {
    isLoading = true
    ParentStateType = ParentStateType

    @AuthStore.Getter('isAuthenticated') isAuthenticated: boolean
    @UserStore.State('boughtBeats') boughtBeats: IBoughtBeat[]

    async mounted() {
        await this.$store.dispatch('user/fetchBoughtBeats')
        this.isLoading = false
    }
}
