























































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import InfiniteLoading from 'vue-infinite-loading'
import BeatTableItem from '@/components/BeatTableItem.vue'
import ListEmptyMessage from '~/components/ListEmptyMessage.vue'
import { ParentStateType } from '~/const/parent-state-type'

@Component({ components: { BeatTableItem, ListEmptyMessage, InfiniteLoading } })
export default class BeatTable extends Vue {
    @Prop() parentState: ParentStateType
    @Prop({ type: Array }) beatPlaylist: IBeat[]
    @Prop({ type: Number }) amountOfPromotedBeats: number

    ParentStateType = ParentStateType

    infiniteHandler($state: any) {
        this.$emit('fetchMoreData', $state)
    }
}
