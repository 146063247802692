









import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class ListEmptyMessage extends Vue {
    @Prop({ type: String }) iconName: string
    @Prop({ type: String }) title: string
    @Prop({ type: String }) subtitle: string
    @Prop({ type: String }) linkTo: string

    get button() {
        if (!this.linkTo) return
        const button: { url: object; text: string } = { url: {}, text: '' }
        if (this.linkTo === 'Browse') {
            button.url = { name: 'Browse' }
            button.text = 'BROWSE BEATS'
        } else {
            button.url = { name: 'Login', query: { redirect: this.$route.fullPath } }
            button.text = 'LOG IN'
        }
        return button
    }
}
