<template lang="pug">
header.header.header__gradient(:class="backgroundGradient", :style="backgroundImgLink")
	.container.container--sm
		h1 {{ heading }}
		p.p--lead {{ subheading }}
</template>

<script>
export default {
    name: 'HeaderSection',
    props: {
        heading: String,
        subheading: String,
        img: String
    },
    computed: {
        backgroundImgLink() {
            if (!this.img) return null
            return `background-image: url('./img/${this.img}.jpg')`
        },
        backgroundGradient() {
            if (!this.img) return null
            else if (this.img === 'bg-licenses') return ['header__gradient--fromLeft']
            return ['header__gradient--fromBottom', 'header__gradient--fromLeft']
        }
    }
}
</script>
